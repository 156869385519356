<template>
  <main id="services">
    <h1 id="servicesTitle">{{ pageName }}</h1>

    <div id="serviceTypesFilter">
      <div id="servicesTabs">
        <div id="reserveDomainBtn" class="servicesTabItem" @click="showReserveDomainPart()" selected="selected">
          <span id="reserveDomainTab" class="servicesTab" @click="showReserveDomainPart()">
            <a>Nom de domaine</a>
          </span>
        </div>

        <div class="servicesTabItem" v-for="(offerType, index) in offerTypes" v-bind:key="index"
          @click="selectServicesTab">
          <span class="servicesTab" @click="selectServicesTab" :offerType="offerType.type">
            <a>{{ offerType.type }}</a>
          </span>
        </div>

        <div id="servicesTabsLine">
          <div id="servicesTabsCursor" ids="0"></div>
        </div>
      </div>

      <!-- <div class="form-check form-switch mt-3 mb-3">
        <small selected v-show="!offerMonth">Annuel</small>
        <small v-show="offerMonth">Annuel</small>
        <input class="form-check-input float-none position-static" type="checkbox"
          v-on:change="offerMonth = !offerMonth" />
        <small selected v-show="offerMonth">Mensuel</small>
        <small v-show="!offerMonth">Mensuel</small>
      </div> -->
    </div>

    <div id="servicesBoard" class="row justify-content-center pt-4">
      <div class="row justify-content-center w-100" v-for="(offerType, tIndex) in offerTypes" v-bind:key="tIndex">
        <div class="servicesItem" v-for="(offer, index) in offersFiltered" v-bind:key="index"
          v-show="offer.type == offerType.type">
          <h5 class="servicesItemText1">{{ offer.label }}</h5>
          <h4 v-show="offerMonth">
            {{ correctPrice(offer.price_per_month) }} FCFA HT / Mois
          </h4>
          <h4 v-show="!offerMonth">
            {{ correctPrice(offer.price_per_year) }} FCFA HT / An
          </h4>
          <button @click="validate(offer)">Selectionner</button>
          <p v-for="(feature, fIndex) in offer.features" v-bind:key="fIndex">
            <i class="bi bi-check"></i><a>{{ feature }}</a>
          </p>
        </div>
      </div>


      <!--  -->
      <!--  -->
      <form id="reserveDomainForm" @submit.prevent="__EC_check()">
        <h2>Acheter un nom de domaine</h2>
        <br />
        <div class="__EC_domainBox">
          <input id="__EC_domain" placeholder="Entrez le nom de domaine que vous désirez..." required />
          <button type="submit">
            <a id="__EC_btn_text">VÉRIFIER</a>
            <svg id="__EC_spin" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#fff"
              viewBox="0 0 16 16">
              <path
                d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
              <path fill-rule="evenodd"
                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
            </svg>
          </button>
        </div>
        <br />

        <div id="__EC_domainStateBox">
          <span v-if="domain.derror">
            Nom de domaine invalide.
            <a :href="rules_url">Consultez les règles.</a>
          </span>

          <div v-else-if="domain.error" class="__EC_domainStateError">
            Serveur innaccessible. Veuillez contactez le support.
          </div>

          <div v-else-if="checkDomainState == 1" class="__EC_domainStateError">
            Pour les noms de domaines prémium, veuillez contacter l'assistance
            de Ecoweb.
          </div>

          <div id="__EC_domainState" v-else>
            <!--  -->
            <div v-if="!domain.available" class="domainInfos">
              <div class="w-100">
                <svg id="__EC_domainStateError" class="__EC_domainStateError" xmlns="http://www.w3.org/2000/svg"
                  width="32" height="32" fill="#a00" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                <span class="ms-1 __EC_domErr domainNotFree">{{
                  domain.name + " est déjà réservé."
                }}</span>
              </div>

              <div class="domainTrans" v-if="domainExtension == '.bf'">
                Si vous en êtes le propriétaire cliquez
                <a @click="openTransfertBfModal()" role="button" class="text-primary fw-bold">ici</a>
                pour éffectuer un transfert chez Ecoweb.
              </div>
            </div>
            <!--  -->
            <div v-else class="domainInfos">
              <!--  -->
              <div class="domainData">
                <svg class="__EC_domainStateValid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="32"
                  height="32" fill="#080">
                  <path
                    d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                </svg>
                <a class="domainFree">
                  <div class="d-inline-block text-truncate me-1">
                    {{ domain.name }}
                  </div>
                  <span class="d-inline-block">est Libre!</span>
                </a>
              </div>

              <div id="__EC_domainText">
                Prix:
                <strike style="color: #8bd" class="stroked-price" v-if="domain.priceOff - 0 != domain.price - 0">{{
                  correctPrice(domain.price - 0) + " " + domain.currency
                }}</strike>
                <strong>{{
                  correctPrice(domain.priceOff - 0) + " " + domain.currency
                }}
                  <b class="text-primary">HT</b></strong>
                <div class="star-badge d-inline-block" v-if="domain.priceOff - 0 != domain.price - 0">
                  {{
                    "-" +
                    (100 - Math.round((domain.priceOff * 100) / domain.price)) +
                    "%"
                  }}
                </div>
                <br />
                Réabonnement:
                <strong>
                  {{ correctPrice(domain.reab - 0) + " " + domain.currency }}
                  <b class="text-primary">HT</b></strong>
              </div>
            </div>
          </div>
          <button type="button" @click="gotoConfirmDomain()" class="__EC_buy" v-if="!domain.derror && domain.available">
            RÉSERVER !
          </button>
          <!--  -->
        </div>
      </form>
    </div>
  </main>
</template>

<script>
const $ = require("jquery");
import waitLoader from "/src/common/js/waitLoader";
import axios from "axios";
import Globals from "/src/common/js/Globals";
import popup from "/src/common/js/popup";
import localforage from "localforage";
import functions from "/src/common/js/functions";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

//services component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      rules_url: Globals.website_url + "DomainRules",
      //
      pageName: "Commander un Service",
      offers: [],
      offersFiltered: [],
      offerTypes: [],
      offerMonth: false,
      //
      EC_isBusy: false,
      //
      checkDomainState: 0,
      //
      domain: {
        name: "---",
        price: "---",
        priceOff: "---",
        reab: "---",
        reabOff: "---",
        currency: "---",
        available: false,
        error: false,
        derror: false,
      },
      //
      domainExtension: "",
    };
  },

  async mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;

    //reset continue subs state
    this.usersStore.User.account.curSubscriptionIds = null;
    try {
      await localforage.setItem(
        "ecocloud/User",
        JSON.stringify(this.usersStore.User)
      );
    } catch (err) {
      // This code runs if there were any errors.
      return;
    }
    //
    this.__update();
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    async update() {
      //get all offers in json
      try {
        const resp = await axios.get(
          Globals.website_url + "php/api.php?query=get-all-services"
        );
        this.offers = resp.data;
        this.offers.forEach((item) => {
          item.features = item.features.split(",");
        });
      } catch (err) {
        waitLoader.hide();
        // Handle Error Here
        console.error(err);
        return;
      }
      //
      //
      //get all offers types in json
      try {
        const resp = await axios.get(
          Globals.website_url + "php/api.php?query=get-services-types"
        );
        this.offerTypes = resp.data;
        //
        setTimeout(() => {
          this.refresh("nan");
          //
          let o = $(".servicesTabItem")[0];
          //set cursor tab at correct position
          let objs = document.getElementsByClassName("servicesTabItem");
          let ids = -1;
          for (let i = 0; i < objs.length; i++) {
            if (o == objs[i]) {
              ids = i;
              break;
            }
          }
          $("#servicesTabsCursor").attr("ids", ids);
          //
          waitLoader.hide();
        }, 1000);
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        console.error(err);
      }
    },

    selectServicesTab(e) {
      $("#reserveDomainForm").css("display", "none");
      //
      let o = e.target;
      if (!o.classList.contains("servicesTab")) {
        o = e.target.parentElement;
        if (!o.classList.contains("servicesTab")) {
          o = e.target.children[0];
        }
      }
      //set cursor tab at correct position
      let objs = document.getElementsByClassName("servicesTabItem");
      let ids = -1;
      for (let i = 0; i < objs.length; i++) {
        if (o == objs[i]) {
          ids = i;
          break;
        }
      }
      //
      for (let i = 0; i < objs.length; i++) {
        objs[i].setAttribute("selected", "");
      }
      //
      o.parentElement.setAttribute("selected", "selected");
      //
      $("#servicesTabsCursor").attr("ids", ids);
      //
      let w = o.getBoundingClientRect().right - o.getBoundingClientRect().left;
      $("#servicesTabsCursor").css("width", w + "px");
      let l =
        o.getBoundingClientRect().left -
        $("#servicesTabs")[0].getBoundingClientRect().left;
      $("#servicesTabsCursor").css("left", l + "px");
      //
      this.refresh(o.getAttribute("offerType"));
    },

    refresh(type) {
      this.offersFiltered = this.offers.filter((offer) => {
        if (offer.type == type) return true;
        return false;
      });
    },

    correctPrice(price) {
      return functions.correctPrice(price);
    },

    async validate(offer) {
      var id = -1;
      this.offers.forEach(function (item) {
        if (item.label.toLowerCase() == offer.label.toLowerCase()) id = item.id;
      });
      if (id == -1) {
        return;
      }
      this.usersStore.User.account.curServiceId = id;
      this.usersStore.User.account.curOfferId = this.offerMonth ? "0" : "1";
      this.usersStore.User.account.curOfferDomainReab = offer.price_per_year;
      //
      this.usersStore.User.account.curOfferModalityId = 0;
      this.usersStore.User.account.curOfferDomain = null;
      this.usersStore.User.account.payOperationType = 2;
      this.usersStore.User.account.hasDomain = offer.has_domain;

      try {
        await localforage.setItem('ecocloud/User', JSON.stringify(this.usersStore.User));
      } catch (err) {
        // This code runs if there were any errors.
      }

      //
      //FB pixel
      window.fbq('trackCustom', 'CheckOffer', { offer_type: offer.type, offer_label: offer.label });
      //Google Analytics
      this.$gtag.event('CheckOffer', { offer_type: offer.type, offer_label: offer.label });


      //save cache data
      try {
        await localforage.setItem(
          "ecocloud/User",
          JSON.stringify(this.usersStore.User)
        );
      } catch (err) {
        // This code runs if there were any errors.
      }
      //
      if (offer.has_domain == 1 || offer.has_domain == 2) {
        this.$router.push("/client/choose-offer-domain");
      }
      else {
        this.$router.push("/client/payment");
      }
    },

    showReserveDomainPart() {
      $("#reserveDomainForm").css("display", "block");
      document.querySelectorAll(".servicesTabItem").forEach((item) => {
        item.setAttribute("selected", "");
      });

      $("#reserveDomainBtn").attr("selected", "selected");
      //
      let o = $("#reserveDomainTab")[0];
      $("#servicesTabsCursor").attr("ids", 0);
      //
      let w = o.getBoundingClientRect().right - o.getBoundingClientRect().left;
      $("#servicesTabsCursor").css("width", w + "px");
      let l =
        o.getBoundingClientRect().left -
        $("#servicesTabs")[0].getBoundingClientRect().left;
      $("#servicesTabsCursor").css("left", l + "px");
      //
      this.refresh("nan");
    },

    //
    //
    //
    async __EC_check() {
      //
      if (this.EC_isBusy) {
        return false;
      }
      this.EC_isBusy = true;
      document.querySelector("#__EC_spin").style.display = "inline";
      document.querySelector("#__EC_btn_text").style.display = "none";
      document.querySelector("#__EC_domainStateBox").style.display = "none";
      //
      let domain = document.querySelector("#__EC_domain").value.toLowerCase();
      let dots = domain.split(".");
      let ext = dots[dots.length - 1];
      //
      //
      this.usersStore.User.account.curServiceId = 0;
      //save cache data
      try {
        await localforage.setItem(
          "ecocloud/User",
          JSON.stringify(this.usersStore.User)
        );
      } catch (err) {
        // This code runs if there were any errors.
      }
      //
      this.__EC_checkDomain(ext);
    },
    //
    //
    //
    __EC_checkDomain(ext) {
      //
      let __EC_domain = document
        .querySelector("#__EC_domain")
        .value.toLowerCase();
      //on domain name error
      if (__EC_domain.length < 3 || __EC_domain.indexOf(".") < 2 || __EC_domain.indexOf(".") > __EC_domain.length - 2) {
        this.domain.derror = true;
        document.querySelector("#__EC_domainStateBox").style.display = "block";
        this.EC_isBusy = false;
        document.querySelector("#__EC_spin").style.display = "none";
        document.querySelector("#__EC_btn_text").style.display = "inline";
        return true;
      }
      //
      let $self = this;
      this.domain.error = false;
      this.domain.derror = false;
      //
      const __EC_xhttp = new XMLHttpRequest();
      __EC_xhttp.onload = async function () {
        let h = this.response;
        try {
          h = JSON.parse(h);
        } catch (e) {
          document.querySelector("#__EC_domainState").innerHTML =
            "Erreur de vérification du nom de domaine.";
          document.querySelector("#__EC_domainState").style.color = "#a00";
          //
          document.querySelector("#__EC_domainStateBox").style.display =
            "block";
          //
          $self.EC_isBusy = false;
          document.querySelector("#__EC_spin").style.display = "none";
          document.querySelector("#__EC_btn_text").style.display = "inline";
          return false;
        }

        //domain checking errors
        $self.checkDomainState = 0;
        $self.domain.available = false;
        if (!h.success) {
          if (h.code == "domain_premium") {
            $self.checkDomainState = 1;
          } else {
            // $self.checkDomainState = 0;
          }
          //
          document.querySelector("#__EC_domainStateBox").style.display =
            "block";
          //
          $self.EC_isBusy = false;
          document.querySelector("#__EC_spin").style.display = "none";
          document.querySelector("#__EC_btn_text").style.display = "inline";
          //
          return false;
        }
        //
        $self.domain.name = __EC_domain;
        $self.domain.available = h.data[__EC_domain].availability;
        $self.domain.price = h.data[__EC_domain].price;
        $self.domain.priceOff = h.data[__EC_domain].price;
        $self.domain.reab = h.data[__EC_domain].reab;
        $self.domain.reabOff = h.data[__EC_domain].reab;
        $self.domain.currency = "FCFA";
        //
        //FB Pixel Event
        window.fbq('trackCustom', 'CheckDomain',
          {
            domain: $self.domain.name,
            available: $self.domain.available,
            price: $self.domain.price,
            renew: $self.domain.reab,
            currency: $self.domain.currency
          }
        );
        //Google Analytics
        $self.$gtag.event('CheckDomain',
          {
            domain: $self.domain.name,
            available: $self.domain.available,
            price: $self.domain.price,
            renew: $self.domain.reab,
            currency: $self.domain.currency
          }
        );
        //
        //on free domain name
        $self.domainExtension = "";
        if ($self.domain.available) {
          $self.usersStore.User.account.hasDomain = 1;
          $self.usersStore.User.account.curOfferDomain = $self.domain.name;
          $self.usersStore.User.account.curOfferDomainPrice = $self.domain.price;
          $self.usersStore.User.account.curOfferDomainReab = $self.domain.reab;
          $self.usersStore.User.account.curOfferModalityId = 1;
          $self.usersStore.User.account.payOperationType = 0;
          try {
            await localforage.setItem(
              "ecocloud/User",
              JSON.stringify($self.usersStore.User)
            );
          } catch (err) {
            // This code runs if there were any errors.
          }
          //
          //
          document.querySelector("#__EC_domainStateBox").style.display =
            "block";
        }
        //on reserved domain name
        else {
          if (ext == "bf") $self.domainExtension = ".bf";
          //
          document.querySelector("#__EC_domainStateBox").style.display =
            "block";
        }
        //
        $self.EC_isBusy = false;
        document.querySelector("#__EC_spin").style.display = "none";
        document.querySelector("#__EC_btn_text").style.display = "inline";
      };
      //
      __EC_xhttp.onerror = function () {
        $self.EC_isBusy = false;
        $self.domain.error = true;
        //
        document.querySelector("#__EC_domainStateBox").style.display = "block";
        //
        document.querySelector("#__EC_spin").style.display = "none";
        document.querySelector("#__EC_btn_text").style.display = "inline";
      };
      //
      //
      __EC_xhttp.open(
        "GET",
        Globals.website_url +
        "php/domain/checkDomain.php?domain=" +
        __EC_domain,
        true
      );
      __EC_xhttp.send();
      //
      return false;
    },
    //

    gotoConfirmDomain() {
      this.$router.push("/client/payment");
    },

    openTransfertBfModal() {
      popup.action = "notification";
      popup.setTitle("");
      popup.setMessage(
        `    
<img src="` +
        Globals.website_url +
        `logo-regsitre-bf.png"/>
<h1 style="color:#c60000;">Transférer votre nom de domaine (.bf)</h1>

<br>

<h3>Procédure:</h3>
<p>
  <h5>
  1. Remplissez et validez le formulaire (<a href="https://abdi.bf/formulaire-de-demande-de-transfert-de-bureau-denregistrement/" target="_blank" class="fw-bold">cliquez ici</a>)
  Tout en renseignant les informations suivantes dans la section "CHOIX DE L'AGENT D'ENREGISTREMENT":
  <br>
  <br>
  Dénomination: ECODEV INTERNATIONAL
  <br>
  E-mail: bf@ecodev.dev
  <br>
  Téléphone: +226 56343412
  </h5>
</p>

<p>
  <h5>
  2. Informez nous à travers le menu Support et Assistance.
  </h5>
</p>

<p>
  <h5>
  3. Nous nous chargeons du reste!
  </h5>
</p>
<br>
<br>
`
      );
      popup.setButton("Compris");
      popup.show();
    },
  },
};
</script>

<style scoped>
#services {
  color: black;
  width: 100%;
  position: relative;
  padding: 24px 12px;
}

#servicesSlogan {
  font-family: "Courier New", Courier, monospace;
  text-transform: uppercase;
}

#servicesTitle {}

/**/
#serviceTypesFilter {
  margin-top: 32px;
  margin-bottom: 24px;
}

#servicesTabs {
  display: block;
  max-width: 840px;
  width: 100%;
  margin: auto;
  margin-top: 64px;
  font-size: 16px;
  text-align: left;
  margin-bottom: 32px;
}

.servicesTabItem {
  float: left;
  width: max-content;
  margin-right: 16px;
}

.servicesTabItem[selected="selected"] .servicesTab {
  color: white;
}

.servicesTab {
  width: max-content;
  cursor: pointer;
  color: black;
}

.servicesTab:hover {
  color: white;
}

.servicesTab i {
  font-size: 20px;
  margin-right: 8px;
}

.servicesTab a {
  font-size: 18px;
}

#servicesTabsLine {
  float: left;
  margin-top: 8px;
  background-color: #111a2b;
  width: 100%;
  height: 2px;
}

#servicesTabsCursor {
  width: 136px;
  background-color: white;
  height: 5px;
  position: relative;
  top: -1px;
  left: 0;
  transition: all 0.4s ease;
  border-radius: 3px;
}

.form-check {
  position: absolute;
  top: 64px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}

.form-check small[selected] {
  color: #803300;
}

.form-check small:first-child {
  position: relative;
  left: -44px;
}

.form-check small:nth-child(2) {
  position: relative;
  left: -44px;
}

.form-check small:nth-child(3) {
  position: relative;
  left: 4px;
}

.form-check small:last-child {
  position: relative;
  left: 4px;
}

.form-check input {
  background-color: #ff6600;
}

.form-check input:checked {
  background-color: #ff6600;
}

/*---------------

---------------*/
#servicesBoard {
  margin: auto;
  margin-top: 32px;
  width: 100%;
  max-width: 924px;
}

.serviceType {}

.servicesItem {
  cursor: pointer;
  background-color: #111a2b;
  padding: 16px 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 32px;
  text-align: center;
  box-sizing: border-box;
  margin-right: 24px;
  width: 280px;
  max-width: 100%;
  border: solid 3px #ff6600;
  margin-right: 10px;
  margin-left: 10px;
  animation: animBounceZ ease 0.75s;
  transition: transform 0.25s ease;
}

@keyframes animBounceZ {
  0% {
    transform: scale(0.95);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(0.975);
  }

  75% {
    transform: scale(1.025);
  }

  100% {
    transform: scale(1);
  }
}

.servicesItem:hover {
  box-shadow: 0 0 16px #ff6600;
  transform: scale(1.025);
}

.servicesItemText1 {
  color: #ff6600;
}

.servicesItem h4 {
  color: #ff6600;
  font-weight: 100 !important;
  font-family: "Calibri light", Courier, monospace;
  margin-bottom: 16px;
}

.servicesItem p {
  display: block;
  width: 100%;
  line-height: 0;
  color: white;
}

.servicesItem i {
  position: relative;
  top: 6px;
  color: chartreuse;
  font-size: 28px;
}

.servicesItem button {
  width: 100%;
  height: 48px;
  background: #ff6600;
  color: white;
  font-weight: bold;
  border: none;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1240px) {
  .servicesItem {
    width: 100%;
  }
}

@media only screen and (max-width: 643px) {
  #servicesBoard {
    max-width: 420px;
  }

  .servicesItem {
    margin-right: 0;
    margin-left: 0;
  }
}

/*  */
/*  */
/*  */
/*  */
/*  */
input,
button:focus,
textarea:focus,
select:focus {
  outline: none;
}

.__EC_domainBox {
  width: 100% !important;
  display: flex !important;
  height: 54px !important;
}

.__EC_domainBox button {
  width: 160px !important;
  height: 100% !important;
  padding: 0px 24px !important;
  background-color: #ff643f !important;
  box-shadow: -100px 0px 68px -1px #fe575b inset !important;
  border: none !important;
  margin-left: 8px !important;
  border-radius: 15px !important;
  cursor: pointer !important;
}

.__EC_domainBox button:hover {
  background-color: #fe575b !important;
  box-shadow: -100px 0px 68px -1px #ff643f inset !important;
}

#__EC_btn_text {
  font-size: 13px !important;
  font-family: tahoma !important;
  color: white !important;
}

#__EC_spin {
  display: none;
  animation-name: __EC_spin !important;
  animation-duration: 1000ms !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
}

@keyframes __EC_spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.domainData {
  height: 32px !important;
}

#reserveDomainForm {
  background-color: white;
  padding: 32px 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
}

.__EC_domainBox input {
  flex-grow: 100 !important;
  height: 100% !important;
  padding: 16px 24px !important;
  background-color: rgb(231, 244, 255) !important;
  font-size: 16px !important;
  overflow: hidden !important;
  font-family: tahoma !important;
  border-radius: 15px !important;
  border: none !important;
}

.__EC_buy {
  float: right !important;
  width: 160px !important;
  height: 48px !important;
  padding: 0px 24px !important;
  background-color: #61ce70 !important;
  box-shadow: -100px 0px 68px -1px #3ea55c inset !important;
  border: none !important;
  border-radius: 15px !important;
  cursor: pointer !important;
  font-family: tahoma !important;
  font-size: 13px !important;
  color: white !important;
}

.__EC_buy:hover {
  background-color: #3ea55c !important;
  box-shadow: -100px 0px 68px -1px #61ce70 inset !important;
}

#__EC_domainStateBox {
  display: none;
  text-align: left;
  width: 100%;
}

#__EC_domainState {
  font-size: 16px !important;
  font-family: tahoma !important;
  width: 75%;
  height: max-content;
}

#__EC_domainText {
  display: block !important;
}

#__EC_domainText2 {
  display: block !important;
}

.__EC_domainStateValid,
.__EC_domainStateError {
  float: left;
  position: relative;
  color: #b00;
  font-size: 20px !important;
  font-family: tahoma !important;
}

.domainTrans {
  float: left;
  margin-top: 4px;
  margin-left: 6px;
  color: black;
  width: calc(100% - 40px) !important;
}

.domainTrans div {
  float: left !important;
  display: inline-block !important;
  max-width: 100% !important;
}

.domainTrans span {
  float: left !important;
  margin-top: 0px !important;
}

.domainFree {
  float: left;
  margin-top: 4px;
  margin-left: 6px;
  color: black;
  width: calc(100% - 40px) !important;
}

.domainFree div {
  float: left !important;
  display: inline-block !important;
  max-width: 100% !important;
}

.domainFree span {
  float: left !important;
  margin-top: 0px !important;
}

.domainNotFree {
  float: left;
  margin-left: 6px;
  color: black;
}

.__EC_domErr {
  position: relative;
  top: 4px;
  color: #b00;
}

.stroked-price {
  margin-right: 8px;
}

.star-badge {
  margin-left: 8px;
  display: inline;
  border-radius: 10px;
  padding: 4px 10px;
  color: white;
  font-size: 15px;
  background-color: #dc3545;
  width: max-content;
  height: max-content;
}

@media only screen and (max-width: 643px) {
  #reserveDomainForm {
    padding: 8px !important;
  }

  .__EC_domainBox {
    display: block !important;
    height: max-content !important;
  }

  .__EC_domainBox input {
    display: block;
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 8px !important;
    height: 48px !important;
  }

  .__EC_domainBox button {
    width: 100% !important;
    margin: 0 !important;
    height: 48px !important;
  }

  #__EC_domainStateBox {
    float: none !important;
    width: 100% !important;
    height: max-content !important;
  }

  #__EC_domainState {
    font-size: 16px !important;
    font-family: tahoma !important;
    width: 100%;
    height: max-content;
  }

  .__EC_domainStateValid,
  .__EC_domainStateError {}

  .domainInfos {
    display: block !important;
    width: 100% !important;
  }

  #__EC_domainText {
    display: inline-block !important;
    margin-bottom: 16px !important;
  }

  #__EC_domainText2 {
    display: inline-block !important;
    margin-bottom: 16px !important;
  }

  .__EC_buy {
    display: block !important;
    float: none !important;
    width: 100% !important;
  }
}
</style>
