<template>
  <main id="factures" class="text-center">
    <div id="facturesFilter">
      <div id="factSearchBox" class="input-group mb-3">
        <i class="input-group-text bi bi-search"></i>
        <input @input="filter" type="search" class="form-control" placeholder="Recherche..." id="facturesSearchInput">
      </div>

      <button id="factUpdateBtn" type="button" class="form-control btn-success border-success w-auto"
        @click="forceUpdate()" enable="true">
        Actualiser <i class="bi bi-arrow-clockwise text-white"></i>
      </button>
    </div>


    <div v-if="factures.length == 0" class="facturesItem row w-100 d-flex text-center justify-content-center" empty>
      <h5>Vous n'avez aucune facture à votre actif</h5>
    </div>


    <div class="facturesItem row p-2" v-for="(facture, index) in factures" v-bind:key="index">
      <div class="facturesItemPart1 col-xl-6 col-12">
        <div class="row facturesSubitem">
          <b class="col-sm-6 col-12">Facture. id</b>
          <a class="col-sm-6 col-12">{{ facture.id }}</a>
        </div>

        <div class="row facturesSubitem" v-if="facture.domain_name != null && facture.domain_name != ''">
          <b class="col-sm-6 col-12">Nom de domaine</b>
          <a class="col-sm-6 col-12">{{ facture.domain_name }}</a>
        </div>

        <div class="row facturesSubitem">
          <b class="col-sm-6 col-12">Mode de Payement</b>
          <a class="col-sm-6 col-12">{{ facture.mobile_service }}</a>
        </div>

        <div v-show="facture.payment_mode_id <= 3" class="row facturesSubitem">
          <b class="col-sm-6 col-12">Mobile</b>
          <a class="col-sm-6 col-12">{{ facture.mobile_number }}</a>
        </div>

        <div v-show="facture.payment_mode_id > 3" class="row facturesSubitem">
          <b class="col-sm-6 col-12">Carte de crédit</b>
          <a class="col-sm-6 col-12">{{ facture.card_number }}</a>
        </div>

        <div class="row facturesSubitem">
          <b class="col-sm-6 col-12">Service</b>
          <a class="col-sm-6 col-12">{{ facture.subscription_type + " " + facture.subscription_label }}</a>
        </div>

        <div class="row facturesSubitem">
          <b class="col-sm-6 col-12">Heure de Payement</b>
          <a class="col-sm-6 col-12">{{ facture.time }}</a>
        </div>
      </div>

      <div class="facturesItemPart2 col-xl-2 col-12 text-center mt-2">
        <a>Date. paiement</a>
        <div class="d-flex w-100 justify-content-center">
          <button class="facturesExpDate">{{ correctDate(facture.date) }}</button>
        </div>
      </div>

      <div class="d-flex justify-content-center col-xl-4">
        <div class="w-auto text-center">
          <div class="facturesItemPart3 col-xl-2 col-12 text-center mt-3">
            <button class="facturesDetailsBtn" v-on:click="factureDetails(facture, index)">Détails</button>
          </div>

          <div class="facturesItemPart3 col-xl-2 col-12 text-center mt-3">
            <button v-on:click="factureToPDF(index)">Version PDF</button>
          </div>
        </div>
      </div>

      <div class="row w-10 mt-2 facturesDetails">
        <hr class="mt-2" />

        <div class="facturesItemPart1 col-sm-6 col-12 float-start">
          <div class="row facturesSubitem">
            <b class="col-sm-4 col-12">Entreprise</b>
            <a class="col-sm-8 col-12">{{ facture.enterprise_name }}</a>
          </div>

          <div class="row facturesSubitem">
            <b class="col-sm-4 col-12">Modalité</b>
            <a class="col-sm-8 col-12">{{ facture.nbr_period_payed + " " + (facture.modality == "M" ?
              "Mois" : (facture.nbr_period_payed == 1 ? "An" : "Ans"))
              }}</a>
          </div>

          <div class="row facturesSubitem">
            <b class="col-sm-4 col-12">Montant HT</b>
            <a class="col-sm-8 col-12">{{ correctPrice(facture.amount) }}<strong> FCFA</strong></a>
          </div>
          <div class="row facturesSubitem">
            <b class="col-sm-4 col-12">TVA ({{ facture.tva_percent }}%)</b>
            <a class="col-sm-8 col-12">
              {{ correctPrice(facture.amount * (facture.tva_percent / 100)) }}<strong> FCFA</strong>
            </a>
          </div>
          <div class="row facturesSubitem">
            <b class="col-sm-4 col-12">Montant TTC</b>
            <a class="col-sm-8 col-12">
              {{ correctPrice(ttc(facture.amount * (1 + facture.tva_percent / 100))) }}<strong> FCFA</strong>
            </a>
          </div>
        </div>

      </div>
    </div>
  </main>
</template>


<script>
const $ = require("jquery");
import axios from "axios";
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";
import moment from "moment";
import waitLoader from "/src/common/js/waitLoader";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";
//

//UserData component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Mes factures",
      factures: [],
      offerTypes: [],
      filterIndex: 0,
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    waitLoader.show();
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    forceUpdate() {
      waitLoader.show();
      setTimeout(() => {
        this.update();
      }, 100);
    },

    async update() {
      //get All user data in json format
      let id = this.usersStore.User.account.id;
      id = functions.correctQueryString(id);
      try {
        const resp = await axios.get(Globals.website_url + "php/api.php?query=get-user-factures-by-id/" + id);
        this.factures = resp.data;
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        console.error(err);
        return;
      }
      //
      waitLoader.hide();
    },

    factureDetails(facture, index) {
      this.factures.forEach((item, id) => {
        if (id != index && item.shown) {
          item.shown = false;
          $(".facturesDetailsBtn")[id].classList.remove("bg-arrow-up");
          $(".facturesDetails")[id].style.display = "none";
        }
        if (id == index) {
          if (!item.shown) {
            item.shown = true;
            $(".facturesDetails")[index].style.display = "block";
            $(".facturesDetailsBtn")[id].classList.add("bg-arrow-up");
          } else {
            item.shown = false;
            $(".facturesDetails")[index].style.display = "none";
            $(".facturesDetailsBtn")[id].classList.remove("bg-arrow-up");
          }
        }
      });
    },

    ttc(price) {
      return Math.ceil(price / 5) * 5;
    },

    correctPrice(price) {
      return functions.correctPrice(price);
    },

    moment(date) {
      return moment(date);
    },

    correctDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    currentDate() {
      const current = new Date();
      return current;
    },

    getYearFromDate(date) {
      return moment(date).format("Y");
    },

    getMonthFromDate(date) {
      let m = moment(date).format("M");
      return m < 10 ? "0" + m : m;
    },

    factureToPDF(id) {
      let fact = this.factures[id];
      window.open(Globals.website_url + "media/factures/" + this.getYearFromDate(fact.date) + "/" + this.getMonthFromDate(fact.date) + "/" + fact.id + ".pdf", "_blank");
    },

    filter(e) {
      var str = e.target.value;
      //
      let objs = document.querySelectorAll(".facturesItem");
      objs.forEach(function (item) {
        $(item).hide();
        //
        if (item.textContent.toLowerCase().indexOf(str.toLowerCase()) >= 0) {
          $(item).show();
        }
      });
    },
  }
};
</script>

<style scoped>
#factures {
  color: black;
  width: 100%;
  float: left;
  text-align: center;
}

#facturesFilter {
  width: calc(100% - 48px);
  max-width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

#factSearchBox {
  margin-left: 3%;
  max-width: 706px;
  width: 50% !important;
  margin-right: 16px;
}

#factUpdateBtn {
  height: 38px;
}





#facturesTitle {
  margin-bottom: 32px;
}

#facturesFilter {
  margin-top: 8px;
  margin-bottom: 24px;
}

#facturesFilter a {
  font-size: 23px;
  margin-right: 4px;
}

#facturesFilter select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
  background-color: white;
  padding: 4px 16px;
  padding-right: 32px;
  /**/
  border: solid 1px #ddd;
  border-radius: 4px;
}




.facturesItem {
  margin: auto;
  max-width: 840px;
  width: 100%;
  padding: 16px 28px;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  color: #111a2b;
  margin-bottom: 24px;
  text-align: left;
}

.facturesItem:not([empty]) {
  transform-origin: top center;
  animation: anim3d ease 0.75s;
}

@keyframes anim3d {
  0% {
    transform: perspective(75em) rotateX(30deg);
  }

  25% {
    transform: perspective(75em) rotateX(-30deg);
  }

  50% {
    transform: perspective(45em) rotateX(15deg);
  }

  75% {
    transform: perspective(45em) rotateX(-15deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}





.facturesSubitem:nth-child(odd) {
  background-color: white;
}

.facturesItem a {
  color: black;
}

.facturesItem b {}

.facturesItem button {
  margin-bottom: 8px;
  border-radius: 16px;
  background-color: #111a2b;
  padding: 8px 0;
  color: white;
  border: none;
  width: 112px;
}

.facturesItem button:hover {
  background-color: #444a66;
}

.facturesExpDate {
  background-color: green !important;
  cursor: default !important;
}

.facturesExpDateOver {
  background-color: #900 !important;
  cursor: default !important;
}

.facturesEmpty {
  width: 90%;
  max-width: 216px;
}

.facturesDetailsBtn {
  background-color: transparent !important;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 4px;
  color: black !important;
  border: solid 3px #111a2b !important;
  padding: 5px 0 !important;
}

.bg-arrow-up {
  background-image: url("/src/assets/images/select-arrow-up.svg");
}

.facturesDetails {
  display: none;
  margin-left: 0px;
}


@media only screen and (max-width:512px) {
  .facturesItem {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #facturesFilter a {
    display: none;
  }

  #facturesFilter {
    display: block;
    width: 100%;
    padding: 0;
  }

  #factSearchBox {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    margin-bottom: 8px;
  }

  #factUpdateBtn {
    margin-bottom: 24px;
    width: 100% !important;
    display: block;
  }
}
</style>