import DomainBfDetails from "/src/views/external/DomainBfDetails.vue";
import RegisterDomainBf from "/src/views/external/RegisterDomainBf.vue";
import DomainBfDocs from "/src/views/external/DomainBfDocs.vue";
import Payment from "/src/views/external/Payment.vue";
import RegisterAccount from "/src/views/external/RegisterAccount.vue";
import DomainBfInfos from "@/views/external/DomainBfInfos.vue";


const routes = [
    {
        name: "domain-bf-form",
        path: "/formulaire-enregistrement-nom-domaine-bf",
        component: RegisterDomainBf
    },
    {
        name: "domain-bf-pdf",
        path: "/domain-bf-pdf",
        component: DomainBfDetails
    },
    {
        name: "domain-bf-docs",
        path: "/telecharger-documents-nom-domaine-bf",
        component: DomainBfDocs
    },
    {
        name: "reserve-domain-confirm",
        path: "/payer-nom-domaine-bf",
        component: Payment
    },
    {
        name: "register-account",
        path: "/enregistrer-mon-compte",
        component: RegisterAccount
    },
    {
        name: "domain-info",
        path: "/domaine-bf-info",
        component: DomainBfInfos
    },
];
//
//
export default routes;