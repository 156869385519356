<template>
    <main id="confirmEmail">
        <form @submit.prevent="validate" class="text-center">
            <img src="@/assets/images/logo-ecodev.png">
            <!--  -->
            <h5>Confirmez votre Email</h5>
            <p>Cliquez ci-dessous pour renvoyer un lien de confirmation.</p>

            <div class="w-100 d-flex justify-content-center">
                <button type="submit" id="confirmEmailValidateBtn" enable="true">
                    Envoyer un lien
                    <i class="bi bi-check-lg"></i>
                </button>
            </div>
            <br>
            <a>Vous pourrez renvoyer un nouveau lien dans <b> {{ ctimer }}</b></a>
        </form>
    </main>
</template>

<script>
const $ = require("jquery");
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";
import popup from "/src/common/js/popup";
import waitLoader from "/src/common/js/waitLoader";
import localforage from "localforage";
//
import axios from "axios";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";

//userChoosePhoto component
export default {
    computed: {
        ...mapStores(useUsersStore),

        ctimer() {
            let ct = this.cutdown - this.counter;
            let min = Math.floor(ct / 60);
            let s = ct - min * 60;
            if (this.counter == -1) {
                return "00:00";
            }
            return (min < 10 ? "0" + min : min) + ":" + (s < 10 ? "0" + s : s);
        },
    },

    data() {
        return {
            userEmail: "",
            cutdown: 1,
            counter: 0,
        };
    },

    created() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.userEmail = urlParams.get('amail');
        //
        if (this.userEmail == "") {
            this.$router.replace("/login");
            return;
        }
    },

    async mounted() {
        try {
            let jsData = await localforage.getItem('ecocloud/common');
            if (jsData == "" || jsData == "{}" || jsData == null) {
            }
            else {
                jsData = JSON.parse(jsData);
                this.cutdown = jsData.cutdown ?? 1;
                this.counter = jsData.counter ?? (this.cutdown == 1 ? -1 : 0);
            }
        }
        catch (err) {
            console.log(err);
        }
        //
        this.update();
        //
        this.count();
    },

    methods: {
        count() {
            setTimeout(() => {
                if (this.counter > -1) {
                    this.counter++;
                    $("#confirmEmailValidateBtn")[0].setAttribute("enable", "false");
                    //
                    if (this.counter >= this.cutdown) {
                        this.cutdown *= 5;
                        this.counter = -1;
                    }
                }
                else {
                    $("#confirmEmailValidateBtn")[0].setAttribute("enable", "true");
                }

                localforage.setItem('ecocloud/common', JSON.stringify({ cutdown: this.cutdown, counter: this.counter })).then(() => {
                }).catch(function (err) {
                    console.log(err);
                });

                this.count();
            }, 1000);
        },

        async update() {
            waitLoader.show();
            //
            let fd = new FormData();
            fd.append("query", "get-user-data-by-email/" + this.userEmail);
            let h = await axios.post(Globals.website_url + "php/api.php", fd);
            h = h.data;
            //
            if (h.length == "0" || h == null) {
                this.$router.push("/login");
            }
            else if (h.creating_step <= 0 && h.email_code != "") {
                if (this.cutdown == 1) {
                    this.counter = 1;
                    this.validate();
                    return;
                }
            }
            else {
                this.usersStore.User.data.email_code = "";
                //save cache data
                localforage.setItem('ecocloud/User', JSON.stringify(this.usersStore.User)).then(() => {
                    this.$router.push("/client/Home");
                }).catch(function (err) {
                    console.log(err);
                });
            }
            //
            waitLoader.hide();
        },

        back: function () {
            this.$router.push("/login");
        },

        validate() {
            if (this.counter != -1) {
                waitLoader.hide();
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                    "Vous devez patienter un moment avant de pouvoir envoyer un lien de confirmation !"
                );
                popup.setButton("Ok");
                popup.show();
                return false;
            }
            //
            let h = functions.syncHttpGet(Globals.website_url + "php/api.php?query=check-if-user-exists-by-email/" + this.userEmail);
            //
            if (h != 1) {
                //say error
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                    "Aucun compte n'est associé à cet Email!"
                );
                popup.setButton("Ok");
                popup.show();
                //
                $("#confirmEmailValidateBtn")[0].setAttribute("enable", "true");
                //
                waitLoader.hide();
                return false;
            }

            //on good email
            let decryptEmail = functions.returnQueryString(this.userEmail);
            h = functions.syncHttpGet(Globals.website_url + "php/mailer/sendConfirmEmailLink.php?email=" + decryptEmail);
            //
            if (h != 1) {
                //
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                    "Echec de l'envoi du lien de confirmation!<br>Votre email n'existe peut être pas.<br>Veuillez reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
                //
                waitLoader.hide();
                return false;
            }
            //
            waitLoader.hide();
            this.counter = 0;
            //
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
                "Un email de confirmation vous a été envoyé.  Vérifiez également vos SPAMs si vous ne le trouvez pas."
            );
            popup.setButton("Ok");
            popup.show();
            return false;
        },
    }
};
</script>

<style scoped>
main {
    width: 100%;
    height: 100vh;
    background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
    background-size: cover;
}


form {
    color: black;
    max-width: 680px;
    width: 100%;
    background-color: #F2FBFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    float: left;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    padding: 112px;
}

form img {
    width: 260px;
    margin-bottom: 32px;
}


form h3 {
    margin-bottom: 24px;
}

form input {
    border: solid 1px #ddd;
    padding: 12px 12px;
}

form button[type=submit] {
    margin-left: 12px;
    margin-top: 32px;
    border: none;
    border-radius: 3px;
    padding: 8px 0;
    width: 240px;
    box-sizing: content-box;
    color: white;
    background-color: #111a2b;
    font-size: 17px;
}

form button[type=submit] i {
    color: rgba(123, 255, 0);
    font-size: 16px;
}


form button[enable=false] {
    background-color: rgb(140, 149, 167);
}

/**/
@media only screen and (max-width: 480px) {
    form {
        box-shadow: none;
        border: solid 1px #ccc;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

@media only screen and (max-height: 512px) {
    form {
        top: 0;
        transform: translateY(0) translateX(-50%);
    }
}
</style>