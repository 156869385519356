<template>
    <main id="recoveryAccount">
        <form @submit.prevent="validate();" class="text-center">
            <img src="@/assets/images/logo-ecodev.png">
            <!--  -->
            <h5>Veuillez saisir votre nouveau mot de passe</h5>
            <br>

            <div class="input-group">
                <span class="input-group-text"><i class="bi bi-lock-fill"></i></span>

                <input type="password" class="form-control text-center" id="password" required
                    placeholder="Nouveau mot de passe">

                <span class="input-group-text" @click="password_show_hide()">
                    <i class="bi bi-eye-fill" id="show_eye"></i>
                    <i class="bi bi-eye-slash-fill d-none" id="hide_eye"></i>
                </span>
            </div>

            <button type="submit" id="forgotPasswordValidateBtn" enable="true">
                Valider le mot de passe
                <i class="bi bi-check-lg"></i>
            </button>
        </form>
    </main>
</template>

<script>
const $ = require("jquery");
import localforage from "localforage";

import functions from "/src/common/js/functions";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
import Globals from "/src/common/js/Globals";



export default {
    data() {
        return {

        };
    },

    methods: {
        validate() {
            let password = document.getElementById("password").value;
            //
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let user_id = urlParams.get('aid');
            //
            if (password.length < 6) {
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                    "Veuillez saisir un mot de passe valide!<br>Au moins 6 caractères avec des chiffres et des lettres et un ou plusieurs symboles."
                );
                popup.setButton("Ok");
                popup.show();
                return false;
            }

            //on good data
            waitLoader.show();
            //
            user_id = functions.correctQueryString(user_id);
            password = functions.correctQueryString(password);

            let h = functions.syncHttpGet(Globals.website_url + "php/api.php?query=reset-user-password/" + user_id + "/" + password);
            if (h != "1") {
                waitLoader.hide();
                //
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                    "Echec du changement de mot de passe!<br>Veuillez reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
                return false;
            }

            //on success redirect to login page
            waitLoader.hide();
            //
            localforage.setItem('ecocloud/common_pwd', JSON.stringify({ cutdown: 1, counter: 0 })).then(() => {
            }).catch(function (err) {
                console.log(err);
            });
            //
            popup.action = "reset password ok";
            popup.setTitle("Notification");
            popup.setMessage(
                "Changement de mot de passe réussi!<br>Vous allez devoir maintenant vous connecter."
            );
            popup.setButton("Ok");
            popup.show();

            //ok
            $("#popupYes").click(() => {
                if (popup.action == "reset password ok");
                this.$router.push("/login");
            });
            //
            return false;
        },
        //

        password_show_hide() {
            var x = document.getElementById("password");
            var show_eye = document.getElementById("show_eye");
            var hide_eye = document.getElementById("hide_eye");
            hide_eye.classList.remove("d-none");
            if (x.type === "password") {
                x.type = "text";
                show_eye.style.display = "none";
                hide_eye.style.display = "block";
            } else {
                x.type = "password";
                show_eye.style.display = "block";
                hide_eye.style.display = "none";
            }
        },
    }
}
</script>

<style scoped>
main {
    width: 100%;
    height: 100vh;
    background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
    background-size: cover;
}

form {
    color: black;
    max-width: 680px;
    width: 100%;
    background-color: #F2FBFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    float: left;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    padding: 112px;
}

form img {
    width: 260px;
    margin-bottom: 32px;
}


form h3 {
    margin-bottom: 24px;
}

form input {
    border: solid 1px #ddd;
    padding: 12px 12px;
}

form button[type=submit] {
    margin-left: 12px;
    margin-top: 32px;
    border: none;
    border-radius: 3px;
    padding: 8px 0;
    width: 240px;
    box-sizing: content-box;
    color: white;
    background-color: #111a2b;
    font-size: 17px;
}

form button[type=submit]:hover {
    color: white;
    background-color: #1b2842;
}

form button[type=submit] i {
    color: rgba(123, 255, 0);
    font-size: 16px;
}

form button[type=submit]:hover {
    background-color: #346;
}

/**/
@media only screen and (max-width: 480px) {
    form {
        box-shadow: none;
        border: solid 1px #ccc;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

@media only screen and (max-height: 512px) {
    form {
        top: 0;
        transform: translateY(0) translateX(-50%);
    }
}
</style>