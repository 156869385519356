<template>
  <div id="bg"></div>
  <center>
    <form @submit.prevent="validate();">
      <h4 class="text-center mb-3">Enregistrement de compte client</h4>
      <h5 class="text-danger fs-6">Vérifiez vos informations pour la création de votre compte client Ecoweb.</h5>

      <div class="mt-4 rDomaintitle">
        <h5>Vos informations client</h5>
      </div>
      <!--  -->
      <div class="row oneRow">
        <div class="form-floating mb-3 p-1">
          <input type="text" id="Fname" spellcheck="false" class="form-control" placeholder="Krepin Jhon"
            :value="data == null ? '' : data.Fname1" @input="resetInput" required />
          <label for="Fname">Nom/Prénoms*</label>
        </div>

        <div class="form-floating mb-3 p-1">
          <input type="email" id="Email" class="form-control" placeholder="krepin@jhon.com"
            :value="data == null ? '' : data.Email1" required />
          <label for="Email">Adresse Email*</label>
        </div>
      </div>
      <!--  -->
      <div class="row oneRow">
        <div class="form-floating mb-3 p-1">
          <CountryList id="Country"></CountryList>
          <label for="Country">Pays*</label>
        </div>

        <div class="form-floating mb-3 p-1">
          <input type="text" id="City" class="form-control" placeholder="Ouagadougou"
            :value="data == null ? '' : data.City1" v-on:input="resetInput" required />
          <label for="City">Ville*</label>
        </div>
      </div>
      <!--  -->
      <div class="row oneRow">
        <div class="form-floating mb-3 p-1">
          <input type="text" id="Address" class="form-control" placeholder="Ouaga 2000"
            :value="data == null ? '' : data.Address1" v-on:input="resetInput" required />
          <label for="Address1">Adresse*</label>
        </div>

        <div class="form-floating mb-3">
          <a style="font-size: 14px">Numéro de téléphone*</a>
          <input type="tel" id="Tel" class="form-control rDomainTel" :value="data == null ? '' : data.Tel1"
            @input="resetInput" required />
        </div>
      </div>
      <!--  -->
      <div class="row oneRow">
        <div class="form-floating mb-3 p-1">
          <input type="password" id="Passwd" class="form-control" v-on:input="resetInput" required />
          <label for="Passwd">Mot de passe*</label>
        </div>

        <div class="form-floating mb-3 p-1">
          <input type="password" id="PasswdConf" class="form-control" v-on:input="resetInput" required />
          <label for="PasswdConf">Confirm. Mot de passe*</label>
        </div>
      </div>

      <!--  -->

      <div class="col-12 d-flex justify-content-center">
        <button type="submit">
          ENREGISTRER ET CONTINUER
          <i class="bi bi-check-lg fs-5"></i>
        </button>
      </div>
    </form>
  </center>
</template>


<script>
const $ = require("jquery");
import waitLoader from "/src/common/js/waitLoader";
import Globals from "/src/common/js/Globals";
import functions from "/src/common/js/functions";
//
import axios from "axios";
import popup from "/src/common/js/popup";
import CountryList from "/src/components/countryList.vue";
//
import * as localforage from "localforage";
import intlTelInput from "intl-tel-input";


//login component
export default {
  components: {
    CountryList,
  },

  data() {
    return {
      data: {},
      //
      intTel: null,
    };
  },

  created() {
  },

  mounted() {
    this.intTel = intlTelInput($("#Tel")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    //
    waitLoader.show();
    //
    setTimeout(async () => {
      let d = await this.getUserFromDb();
      waitLoader.hide();
      if (!d) {
        location.href = "/?signup=1#/login";
      }
    }, 100);
  },

  methods: {
    async getUserFromDb() {
      //get parameters
      //check if a admin try to login as current user
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get("uid") == null) {
        return false;
      }
      let uid = urlParams.get("uid");
      //
      try {
        let resp = await axios.get(Globals.website_url + "php/api.php?query=get-user-data-by-id/" + functions.correctQueryString(uid));
        let h = resp.data;
        if (h == null || typeof (h) == "string") {
          return false;
        }
        this.data.Fname1 = h.fname;
        this.data.Email1 = h.email;
        this.data.Address1 = h.home_address;
        this.data.Country1 = h.country;
        this.data.City1 = h.city;
        this.data.Tel1 = h.tel;
        //
        $("#Country").val(this.data.Country1);
        return true;
        //
      } catch (err) {
        console.log(err);
        return false;
      }
    },

    getFromCache() {
      localforage.getItem("ecocloud/fullaccess").then(async (result) => {
        let jsData = result;
        if (jsData == "" || jsData == "{}" || jsData == null) {
          this.data = null;
          waitLoader.hide();
          return;
        }
        try {
          jsData = JSON.parse(jsData);
        } catch (e) {
          this.data = null;
          waitLoader.hide();
          location.href = "/?signup=1#/login";
          return;
        }
        //
        this.data = jsData;
        //
        this.domainName = this.data.Domain;
        //
        //
        this.data.Tel1 = this.data.Tel1.replace(".", "");
        this.data.Tel2 = this.data.Tel2.replace(".", "");
        this.data.Tel3 = this.data.Tel3.replace(".", "");
        this.data.Tel4 = this.data.Tel4.replace(".", "");
        //
        $("#Country").val(this.data.Country1);
        //
        waitLoader.hide();
        //
      }).catch((err) => {
        console.log(err);
        //
        location.href = "/?signup=1#/login";
      });
    },

    validate() {
      waitLoader.show();
      //
      let fullname = document.getElementById("Fname").value;
      let email = this.data.Email1;
      let password = document.getElementById("Passwd").value;
      let cpassword = document.getElementById("PasswdConf").value;
      let tel = this.intTel.getNumber();
      let country = $("#Country")[0].value;
      let city = $("#City")[0].value;
      let address = $("#Address")[0].value;

      if (cpassword != password) {
        waitLoader.hide();
        //say error
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Les mot de passe saisies ne sont pas identiques!"
        );
        popup.setButton("Ok");
        popup.show();
        return;
      }
      //encryt data
      fullname = functions.correctQueryString(fullname);
      email = functions.correctQueryString(email);
      password = functions.correctQueryString(password);
      tel = functions.correctQueryString(tel);
      country = functions.correctQueryString(country);
      city = functions.correctQueryString(city);
      address = functions.correctQueryString(address);
      //
      //try to create user account
      axios.post(
        Globals.website_url + "php/api.php?query=auto-set-user-data/" +
        email +
        "/" +
        fullname +
        "/" +
        country +
        "/" +
        city +
        "/" +
        address +
        "/" +
        tel +
        "/" +
        password
      ).then((response) => {
        let h = response.data;
        //on error
        if (h != "1") {
          //
          waitLoader.hide();
          //say error
          popup.action = "Notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Erreur d'enregistrement des données!<br>Veuillez reéssayer."
          );
          popup.setButton("Ok");
          popup.show();
          return;
        }
        //
        //on success
        //FB Pixel event
        window.fbq('trackCustom', 'ExternalCreateUser',
          {
            domain: this.data.Domain,
          }
        );
        //Google Analytics
        this.$gtag.event('ExternalCreateUser',
          {
            domain: this.data.Domain,
          }
        );
        //
        this.sendConfirmEmail();
        waitLoader.hide();
        //
      }).catch((error) => {
        console.log(error);
        //
        waitLoader.hide();
        return;
      });
    },

    sendConfirmEmail() {
      //on good email
      let email = this.data.Email1;
      let h = functions.syncHttpGet(Globals.website_url + "php/mailer/sendConfirmEmailLink.php?email=" + email);
      //
      if (h != 1) {
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Echec de l'envoi du lien de confirmation!<br>Votre email n'existe peut être pas.<br>Veuillez reéssayer."
        );
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
        return;
      }
      //
      waitLoader.hide();
      //
      popup.action = "confirm-email";
      popup.setTitle("Notification");
      popup.setMessage(
        "Félicitation, votre compte a bien été créé ! Un email de confirmation vous a été envoyé."
      );
      popup.setButton("Ok");
      popup.show();

      $("#popupYes").click(() => {
        if (popup.action == "confirm-email") {
          this.$router.push("/login");
        }
      });

      return;
    },
  },
};
</script>

<style scoped>
@import "intl-tel-input/build/css/intlTelInput.css";


#bg {
  background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


form {
  margin: 20px 0;
  width: 100%;
  max-width: 800px;
  background-color: #f2fbff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

form input[readonly] {
  background-color: #f8f8f8;
  color: #888;
}

form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: white;
}

form button[type="submit"] {
  margin-left: 12px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

form button[type="submit"]:hover {
  color: white;
  background-color: #1b2842;
}

form button[type="submit"] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

form button[type="submit"]:hover {
  background-color: #346;
}

.rDomaintitle {
  border-bottom: solid 1px black;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.rDomaintitle button {
  float: left !important;
}

.form-floating label {
  opacity: 0.5;
}

.rDomainTel {
  width: 100% !important;
}

.oneRow div {
  display: inline !important;
  width: 50% !important;
}

.oneRow3 div:first-child {
  display: inline !important;
  width: 40% !important;
}

.oneRow3 div:nth-child(2) {
  display: inline !important;
  width: 25% !important;
}

.oneRow3 div:last-child {
  display: inline !important;
  width: 35% !important;
}

.oneRowItem div {
  display: inline !important;
  width: 100% !important;
}


@media only screen and (max-width:512px) {
  .oneRow div {
    width: 100% !important;
  }
}
</style>
