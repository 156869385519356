export default {
  syncHttpPost(url, data) {
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", url, false);
    xhttp.send(data);
    return xhttp.responseText;
  },

  syncHttpPostJSON(url, data) {
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", url, false);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.send(data);
    return xhttp.responseText;
  },

  syncHttpGet(url) {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", url, false);
    xhttp.send();
    return xhttp.responseText;
  },

  correctPrice(price) {
    //turn price in str;
    price = price + "";
    //
    let str = "";
    let end = price.indexOf(".") >= 0 ? price.indexOf(".") - 1 : price.length - 1;
    for (let i = end; i >= 0; i--) {
      str = price[i] + str;
      if ((end + 1 - i) % 3 == 0 && i > 0) str = " " + str;
    }
    //return correct price
    return str + (price.indexOf(".") >= 0 ? "." + price.substr(price.indexOf(".") + 1) : "");
  },

  correctQueryString(str) {
    str = str + "";
    if (str == "") return "$[xNULLx]$";
    //
    str = str.replaceAll("/", "$[xbx]$");
    str = str.replaceAll("\\", "$[xcx]$");
    str = str.replaceAll("%", "$[xdx]$");
    str = str.replaceAll("+", "$[xfx]$");
    str = str.replaceAll("&", "$[xgx]$");
    str = str.replaceAll("=", "$[xhx]$");
    str = str.replaceAll("?", "$[xjx]$");
    str = str.replaceAll(":", "$[xkx]$");
    str = str.replaceAll("#", "$[xlx]$");
    str = str.replaceAll("-", "$[xmx]$");
    //
    str = str.replaceAll("a", "$[xnx]$");
    str = str.replaceAll("e", "$[xpx]$");
    str = str.replaceAll("i", "$[xqx]$");
    str = str.replaceAll("o", "$[xrx]$");
    str = str.replaceAll("u", "$[xsx]$");
    str = str.replaceAll("y", "$[xtx]$");
    //
    str = str.replaceAll("A", "$[xvx]$");
    str = str.replaceAll("E", "$[xwx]$");
    str = str.replaceAll("I", "$[xxx]$");
    str = str.replaceAll("O", "$[xzx]$");
    str = str.replaceAll("U", "$[xBx]$");
    str = str.replaceAll("Y", "$[xCx]$");
    //  
    str = str.replaceAll("0", "$[xDx]$");
    str = str.replaceAll("1", "$[xFx]$");
    str = str.replaceAll("2", "$[xGx]$");
    str = str.replaceAll("3", "$[xHx]$");
    str = str.replaceAll("4", "$[xJx]$");
    str = str.replaceAll("5", "$[xKx]$");
    str = str.replaceAll("6", "$[xLx]$");
    str = str.replaceAll("7", "$[xMx]$");
    str = str.replaceAll("8", "$[xNx]$");
    str = str.replaceAll("9", "$[xPx]$");
    //
    return str;
  },

  returnQueryString(str) {
    str = str + "";
    str = str.replaceAll("$[xNULLx]$", "");
    //
    str = str.replaceAll("$[xbx]$", "/");
    str = str.replaceAll("$[xcx]$", "\\");
    str = str.replaceAll("$[xdx]$", "%");
    str = str.replaceAll("$[xfx]$", "+");
    str = str.replaceAll("$[xgx]$", "&");
    str = str.replaceAll("$[xhx]$", "=");
    str = str.replaceAll("$[xjx]$", "?");
    str = str.replaceAll("$[xkx]$", ":");
    str = str.replaceAll("$[xlx]$", "#");
    str = str.replaceAll("$[xmx]$", "-");
    //
    str = str.replaceAll("$[xnx]$", "a");
    str = str.replaceAll("$[xpx]$", "e");
    str = str.replaceAll("$[xqx]$", "i");
    str = str.replaceAll("$[xrx]$", "o");
    str = str.replaceAll("$[xsx]$", "u");
    str = str.replaceAll("$[xtx]$", "y");
    //
    str = str.replaceAll("$[xvx]$", "A");
    str = str.replaceAll("$[xwx]$", "E");
    str = str.replaceAll("$[xxx]$", "I");
    str = str.replaceAll("$[xzx]$", "O");
    str = str.replaceAll("$[xBx]$", "U");
    str = str.replaceAll("$[xCx]$", "Y");
    //  
    str = str.replaceAll("$[xDx]$", "0");
    str = str.replaceAll("$[xFx]$", "1");
    str = str.replaceAll("$[xGx]$", "2");
    str = str.replaceAll("$[xHx]$", "3");
    str = str.replaceAll("$[xJx]$", "4");
    str = str.replaceAll("$[xKx]$", "5");
    str = str.replaceAll("$[xLx]$", "6");
    str = str.replaceAll("$[xMx]$", "7");
    str = str.replaceAll("$[xNx]$", "8");
    str = str.replaceAll("$[xPx]$", "9");
    //
    return str;
  },

  correctPhoneNumberWithoutCQS(str) {
    str = str + "";
    //autocorrect and detect phone number
    str = str.replaceAll(" ", "");
    str = str.replaceAll("-", "");
    str = str.replaceAll(".", "");
    if (str.substr(0, 2) == "00" && str.substr(2, 1) != 0) {
      str = "+" + str.substr(2, 1000);
    }
    return str;
  },

  correctPhoneNumber(str) {
    str = str + "";
    //autocorrect and detect phone number
    return this.correctQueryString(this.correctPhoneNumberWithoutCQS(str));
  },

  generateEmailProPassword() {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';
    const symbols = '!@#$%^&*¤£µ()_+[]{}|;:,.<>?';

    function getRandomElementFromArray(array) {
      return array[Math.floor(Math.random() * array.length)];
    }

    let password = '';

    for (let i = 0; i < 5; i++) {
      password += getRandomElementFromArray(uppercaseChars);
      password += getRandomElementFromArray(lowercaseChars);
      password += getRandomElementFromArray(digits);
      password += getRandomElementFromArray(symbols);
    }

    return password;
  },

  getDeviceType() {
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);

    if (isMobile) {
      return 'Mobile';
    } else {
      return 'Desktop';
    }
  },


  getDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPhone/i.test(userAgent)) {
      return 'iPhone';
    }

    if (/iPad/i.test(userAgent)) {
      return 'iPad';
    }

    if (/Android/i.test(userAgent)) {
      return 'Android Device';
    }

    if (/Windows Phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/tablet/i.test(userAgent)) {
      return 'Tablet';
    }

    if (/Mobile/i.test(userAgent)) {
      return 'Mobile Device';
    }

    return 'Desktop';
  },


  getOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    if (/win/i.test(userAgent)) {
      return "Windows";
    }

    if (/mac/i.test(userAgent)) {
      return "Mac OS";
    }

    if (/linux/i.test(userAgent)) {
      return "Linux";
    }

    return "Unknown OS";
  },


  formatDateToYMDHMS(date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  },





  async decryptText(ciphertext, secretKey, iv) {
    // Importation de la clé
    const key = await window.crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(secretKey),
      { name: 'AES-GCM', length: 256 },
      false,
      ['decrypt']
    );

    // Décryptage
    const decryptedData = await window.crypto.subtle.decrypt(
      { name: 'AES-GCM', iv },
      key,
      ciphertext
    );

    return new TextDecoder().decode(decryptedData);
  }
}