<template>
  <main>
    <br>
    <div id="renewChooseOffer">

      <div id="renewChooseOfferBoard" class="row">

        <div class="renewChooseOfferItem">
          <img src="@/assets/images/subscription.png">
          <div class="renewChooseOfferItemContent">
            <h4 id="renewChooseOfferTitle">Renouveller le service {{ service.label }}</h4>
            <h4 id="renewChooseOfferTitle">
              pour le nom de domaine <strong><i>{{ subscription.domain_name }}</i></strong>
            </h4>
          </div>
        </div>

        <div class="renewChooseOfferItem">
          <div class="renewChooseOfferItemContent text-start">
            <h4 id="renewChooseOfferTitle">Choix de l'offre et du montant du renouvellement</h4>
            <select id="renewChooseOfferModality" class="renewSelect" @change="setOfferId()">
              <option value="-1" selected="selected">Choisissez une offre</option>
              <!-- <option value="0">Offre mensuelle</option> -->
              <option value="1">Offre annuelle</option>
            </select>
          </div>

          <br>

          <!-- 
          <div class="renewChooseOfferItemContent text-start">
            <h5 v-show="offerId == 0 || offerId == 1">Choisissez votre modalité de paiement</h5>
            <select v-show="offerId == 0 ? true : false" id="renewChooseOfferCount" v-on:change="setOfferModalityId()">
              <option value="-1" selected="selected">Choisissez le paiment</option>
              <option v-for="(offerModality, index) in offerModalityByMonth" :value="offerModality - 1"
                v-bind:key="index">
                {{ offerModality }} Mois - <strong>{{ correctPrice(service.price_per_month * offerModality) }}</strong>
                Fcfa
              </option>
            </select>

            <select v-show="offerId == 1 ? true : false" id="renewChooseOfferCount2"
              v-on:change="setOfferModality2Id()">
              <option value="-1" selected="selected">Choisissez le paiment</option>
              <option v-for="(offerModality, index) in offerModalityByYear" :value="offerModality - 1"
                v-bind:key="index">
                {{ offerModality }} Année{{ offerModality <= 1 ? "" : "s" }} - <strong>
                  {{ correctPrice(service.price_per_year * offerModality) }}</strong> Fcfa
              </option>
            </select>
          </div>
          -->
        </div>
      </div>

      <div class="w-100 d-flex justify-content-center">
        <div id="roBtns" class="btn-group btn-group-toggle" data-toggle="buttons">
          <button @click="back()" class="chooseOfferItemBackBtn btn btn-secondary">
            <i class="bi bi-arrow-left"></i></button>
          <button @click="validate()" class="chooseOfferItemValidateBtn btn btn-warning">
            Poursuivre la Commande<i class="bi bi-caret-right-fill"></i>
          </button>
        </div>
      </div>

      <br>
      <br>
      <br>
    </div>
  </main>
</template>


<script>
const $ = require("jquery");
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
import localforage from "localforage";
import axios from "axios";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";

//ChooseOffer component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Renouveler un service",
      service: {},
      offerId: -1,
      offerModalityId: -1,
      offerModalityByMonth: [1],
      offerModalityByYear: [1],
      subscription: {},
    };
  },

  mounted() {
    waitLoader.show();
    //
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    update() {
      //get all services in json
      const getSelectedService = async () => {
        try {
          let eid = functions.correctQueryString(this.usersStore.User.data.enterprise_id + '');
          let serviceId = functions.correctQueryString("" + this.usersStore.User.account.curServiceId);
          const resp = await axios.get(Globals.website_url + "php/api.php?query=get-service-by-id/" + eid + "/" + serviceId);
          this.service = resp.data;
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      };
      getSelectedService();
      //
      const getSubscriptionData = async () => {
        try {
          let curSubsIds = this.usersStore.User.account.curSubscriptionIds;
          curSubsIds = functions.correctQueryString(curSubsIds);
          let resp = await axios.get(Globals.website_url + "php/api.php?query=get-offer-by-id/" + curSubsIds);
          this.subscription = resp.data;
          //
          //get user data in json
          let userId = this.usersStore.User.account.id;
          userId = functions.correctQueryString(userId);

          resp = await axios.get(
            Globals.website_url +
            "php/api.php?query=get-user-subscription-by-id/" +
            userId +
            "/" +
            this.usersStore.User.account.curSubscriptionIds
          );
          //
          this.service.price_per_year = resp.data.price_per_year;
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      };
      getSubscriptionData();
    },

    correctPrice(price) {
      return functions.correctPrice(price);
    },

    setOfferId() {
      this.offerId = $("#renewChooseOfferModality")[0].value;
    },

    back() {
      this.$router.push("/client/Subscriptions/Services");
    },

    async validate() {
      //on error
      if (this.offerId == -1) {
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Vous devez faire un choix d'offre.");
        popup.setButton("Ok");
        popup.show();
        return;
      }

      //on good infos store data into session data
      this.usersStore.User.account.curOfferId = this.offerId;
      this.usersStore.User.account.curOfferModalityId = 1;
      this.usersStore.User.account.payOperationType = 3;
      //save cache data
      try {
        await localforage.setItem('ecocloud/User', JSON.stringify(this.usersStore.User));
      } catch (err) {
        // This code runs if there were any errors.
      }

      //on success
      this.$router.push("/client/payment");
    },
  }
}
</script>


<style scoped>
/*-------------------------------
ConfirmOrder
-------------------------------*/
#renewConfirmOrder {
  max-width: 840px;
  width: 100%;
  margin: auto;
  font-size: 16px;
}

#renewChooseOffer {
  max-width: 840px;
  width: 100%;
  margin: auto;
  font-size: 16px;
}

.renewChooseOfferItem {
  cursor: pointer;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 32px;
  padding: 24px 24px 24px 24px;
  width: 100%;
}

.renewChooseOfferItem img {
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.renewChooseOfferItemContent {
  display: block !important;
  width: 100%;
  height: max-content;
  margin-top: 16px;
}

.renewChooseOfferItemContent p {
  width: 100%;
  float: left;
  margin-top: -56px;
}

select {
  float: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
  background-color: white;
  padding: 4px 16px;
  padding-right: 32px;
  /**/
  border: solid 1px #ddd;
  border-radius: 4px;
}

h5 {
  width: max-content !important;
}

.renewConfirmOrderBox {
  width: 100%;
  margin: auto;
  background-color: #F2FBFF;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  padding: 28px;
  color: #111a2b;
  text-align: left;
  margin-bottom: 32px;
}

.renewConfirmOrderTitle {
  color: orange !important;
}

.renewConfirmOrderItems {
  margin-top: 8px;
  width: 100%;
  height: max-content;
}

.renewConfirmOrderItems {
  margin-bottom: 32px;
}

.renewConfirmOrderItem:nth-child(odd) {
  background-color: white;
}

.renewConfirmOrderItem a {
  color: black;
}

.renewConfirmOrderItem p {
  overflow: auto;
  max-height: 128px;
}

.renewConfirmOrderItemBackBtn {
  position: relative;
  top: 10px;
  width: max-content;
  padding: 8px 14px;
  color: white;
  border: none;
  background: linear-gradient(to bottom, red, orange);
  font-weight: bold;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
}

.renewConfirmOrderItemBackBtn:hover {
  box-shadow: 0 0 16px #f00;
}

.renewConfirmOrderItemBackBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}



.renewConfirmOrderItemValidateBtn {
  width: max-content;
  padding: 8px 24px;
  color: white;
  border: none;
  background: linear-gradient(to bottom, red, orange);
  font-weight: bold;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
}

.renewConfirmOrderItemValidateBtn:hover {
  box-shadow: 0 0 16px #f00;
}

.renewConfirmOrderItemValidateBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

@media only screen and (max-width:316px) {
  .renewConfirmOrderItemBackBtn {
    position: static;
    margin-bottom: 16px;
  }
}


#roBtns button:first-child {
  max-width: 44px;
}
</style>