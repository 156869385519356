<template>
  <div id="rDomain">
    <center>
      <h3>Paiement par Virement Bancaire</h3>
      <form class="row mb-3" @submit.prevent="validate()">
        <h3>Veuillez entrer le numéro du virement bancaire</h3>
        <input type="text" class="form-control mb-3" v-model="ckeckNumber" />
        <button type="submit" class="form-control mb-3">Valider</button>
        <p>
          NB: En cas d'urgence, nous vous recommandons d'utiliser plutot le
          payment par Carte Bancaire ou Mobile Money. Le temps de validation via
          ces methodes de paiements étant beaucoup plus court
        </p>
      </form>

      <div class="row d-flex justify-content-center">
        <div class="infoBox">
          <h5>Détails du compte</h5>
          <div class="info">
            <div>
              <b>Nom:</b>
              <p>ECODEV INTERNATIONAL</p>

              <b>RCCM:</b>
              <p>BFOUA2020B11461</p>

              <b>IFU:</b>
              <p>00148387L</p>

              <b>Adresse:</b>
              <p>Sect51, Sanyiri, Ouagadougou, Burkina Faso</p>

              <b>Tel:</b>
              <p>+226 56343412</p>

              <b>Code Pays:</b>
              <p>BF51</p>

              <b>Code Banque:</b>
              <p>BF083</p>
            </div>

            <div>
              <b>Code Guichet:</b>
              <p>00001</p>

              <b>Clé RIB:</b>
              <p>12</p>

              <b>Numéro de compte:</b>
              <p>170957774001</p>

              <b>IBAN:</b>
              <p>BF51BF0830000117095777400112</p>

              <b>SWIFT:</b>
              <p>ECOCBFBF</p>

              <b>Banque:</b>
              <p>ECOBANK</p>

              <b>ID de virement:</b>
              <p>688840</p>
            </div>
          </div>
        </div>
      </div>
    </center>
  </div>
</template>

<script>
const $ = require("jquery");
// import axios from "axios";
//
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
// import functions from "/src/common/js/functions";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

let currentDate = new Date();
let annee = currentDate.getFullYear();
let mois = currentDate.getMonth() + 1;
let jour = currentDate.getDate();
let heure = currentDate.getHours();
let minute = currentDate.getMinutes();
let seconde = currentDate.getSeconds();
let transaction_id = "" + annee + mois + jour + heure + minute + seconde;

export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Confirmation du service",
      //
      subsExists: false,
      //
      domain: "",
      ckeckNumber: "",
      //
      transaction_id: transaction_id,
    };
  },

  mounted() {
    $("#routeName")[0].innerHTML = this.pageName;
    //
    this.__update();
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    update() {
      waitLoader.hide();
    },
    //
    async validate() {
      $("#popupYes").click(() => {
        if (popup.action != "ok") return;
        //
        waitLoader.hide();
        this.$router.push("/client/Subscriptions/services");
      });

      waitLoader.show();
      //
      let user = this.usersStore.User;
      var dbData = new FormData();
      dbData.append("subs_id", user.account.curSubscriptionIds);
      dbData.append("user_id", user.data.id);
      dbData.append("transaction_id", "BNK_" + this.transaction_id);
      // dbData.append("amount", this.service.price_per_year_tva);
      dbData.append("customer_name", user.data.fname);
      dbData.append("customer_surname", user.data.fname);
      dbData.append("customer_email", user.data.email);
      dbData.append("customer_phone_number", user.data.tel);
      dbData.append("customer_city", user.data.city);
      dbData.append("description", user.account.curOfferDomain);
      dbData.append("check_number", this.ckeckNumber);
      dbData.append("tva", user.account.curTva);
      dbData.append("offer_id", user.account.curOfferId);
      dbData.append("amount", user.account.amount);
      dbData.append("nbYear", user.account.nbYear);
      //
      try {
        const response = await fetch(
          Globals.website_url + "php/payment-api/transfertPay.php",
          {
            method: "POST",
            body: dbData,
          }
        );

        let data = await response.text();

        if (data != 1) {
          waitLoader.hide();
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème d'enregistrement des données.");
          popup.setButton("Ok");
          popup.show();
          //
          return;
        }
        //
        popup.action = "ok";
        popup.setTitle("Notification");
        popup.setMessage("Enregistrement de paiement réussi!");
        popup.setButton("Ok");
        popup.show();
        waitLoader.hide();
        //
      } catch (error) {
        waitLoader.hide();
        //say error
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuilez vérifier votre connexion à internet puis reéssayer."
        );
        popup.setButton("Ok");
        popup.show();
      }
    },
  },
};
</script>

<style scoped>
@import "intl-tel-input/build/css/intlTelInput.css";

#rDomain {
  padding: 24px 12px;
  overflow-y: auto;
  position: relative;
  background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  transition: left 0.25s ease;
  height: max-content;
  left: 0;
}

/*----------------------------------
  USER DATA EDIT
  ----------------------------------*/
#rDomain form {
  max-width: 700px;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

#rDomain .infoBox {
  max-width: 700px;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

#rDomain form input[readonly] {
  background-color: #f8f8f8;
  color: #888;
}

#rDomain form button[type="submit"] {
  margin-left: 12px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

#rDomain form button[type="submit"]:hover {
  color: white;
  background-color: #1b2842;
}

#rDomain form button[type="submit"] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

#rDomain form button[type="submit"]:hover {
  background-color: #346;
}

.info p {
  font-size: 13px !important;
}

.info {
  display: flex;
  gap: 100px;
}
</style>
